import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getUserPrograms } from '../actions/user';
import useCurrentUser from '../hooks/useCurrentUser';

export default function ProgramStatusProvider(props) {
  const dispatch = useDispatch();
  const user = useCurrentUser();

  useEffect(() => {
    dispatch(getUserPrograms(user.userId));
  }, [user, dispatch]);

  return props.children;
}
