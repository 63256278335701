import isQuestionComplete from '@tmap/mmm-core/questions/isQuestionComplete';

export const FETCH_QUESTION_MODULE = 'FETCH_QUESTION_MODULE';
export const RECEIVE_QUESTION_MODULE = 'RECEIVE_QUESTION_MODULE';
export const UPDATE_QUESTION_FIELDS = 'UPDATE_QUESTION_FIELDS';

const defaultState = {
  modules: {/*
    {
      name: string,
      isComplete: boolean,
      steps: [
        {
          name: string,
          isHook: boolean,
          isComplete: boolean,
          components: [
            { type: 'slate', slate: [...] },
            {
              type: 'question':
              questionFieldId: string,
              questionField: {...},
              answer: any,
              isComplete: boolean,
            },
          ]
        }
      ],
  */},
};

const questionsReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCH_QUESTION_MODULE: {
      return {
        ...state,
        modules: {
          ...state.modules,
          [action.moduleName]: {
            isFetching: true,
          },
        },
      };
    }
    case RECEIVE_QUESTION_MODULE: {
      const steps = action.data.steps.map((step) => {
        const components = step.components.map((component) => {
          if (component.questionField) {
            return {
              ...component,
              isComplete: isQuestionComplete(component.questionField, component.answer),
            };
          }
          return component;
        });
        return {
          ...step,
          isComplete: components.every(({ questionField, isComplete }) => (
            !questionField || isComplete
          )),
          components,
        };
      });
      return {
        ...state,
        modules: {
          ...state.modules,
          [action.moduleName]: {
            isFetching: false,
            ...action.data,
            steps,
            isComplete: steps.every(({ isComplete }) => isComplete),
          },
        },
      };
    }
    case UPDATE_QUESTION_FIELDS: {
      return {
        ...state,
        modules: Object.fromEntries(
          Object.entries(state.modules).map(([moduleName, module]) => {
            if (!module.steps) {
              console.error(`Module without steps: ${moduleName}: ${JSON.stringify(module)}`);
              return [moduleName, module];
            }
            const steps = module.steps.map((step) => {
              const components = step.components.map((component) => {
                if (component.questionFieldId in action.answers) {
                  const answer = action.answers[component.questionFieldId];
                  return {
                    ...component,
                    answer,
                    isComplete: component.questionField
                      ? isQuestionComplete(component.questionField, answer)
                      : component.isComplete,
                  };
                }
                return component;
              });
              return {
                ...step,
                isComplete: components.every(({ questionField, isComplete }) => (
                  !questionField || isComplete
                )),
                components,
              };
            });
            return [
              moduleName,
              {
                ...module,
                steps,
                isComplete: steps.every(({ isComplete }) => isComplete),
              },
            ];
          }),
        ),
      };
    }
    default: {
      return state;
    }
  }
};

export default questionsReducer;
