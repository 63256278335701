import { combineReducers } from 'redux';
import thunktankReducer from '@tmap/mmm-core/redux/thunktank/thunktankReducer';
import viewsReducer from '@tmap/mmm-core/redux/views/viewsReducer';
import documentCacheReducer from '@tmap/mmm-core/redux/documentCache/documentCacheReducer';
import userReducer from './user';
import authReducer from './auth';
import incentiveReducer from './incentive';
import commonAppReducer from './commonApp';
import communityReducer from './community';
import communityEditorReducer from './communityEditor';
import tutorialsReducer from './tutorials';
import questionsReducer from './questions';

const rootReducer = combineReducers({
  users: userReducer,
  authStore: authReducer,
  documentCache: documentCacheReducer,
  incentives: incentiveReducer,
  communities: communityReducer,
  communityEditor: communityEditorReducer,
  commonApp: commonAppReducer,
  thunktank: thunktankReducer,
  views: viewsReducer,
  tutorials: tutorialsReducer,
  questions: questionsReducer,
});

export default rootReducer;
