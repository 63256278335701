import { ThemeProvider, CssBaseline } from '@tmap/mmm-style-guide/src/Theming';
import { CacheProvider } from '@emotion/react';
import { Auth0Provider } from '@auth0/auth0-react';

import '../styles/globals.css';

import React, { useEffect } from 'react';
import { wrapper } from '../store';
import GlobalFonts from './_fonts';

import { LIGHT_THEME } from '../lib/themes';
import PageLoadedProvider from '../components/pageLoadedProvider';
import ProgramStatusProvider from '../components/programStatusProvider';
import UserFavoritesProvider from '../components/userFavoritesProvider';
import createEmotionCache from '../lib/createEmotionCache';
import analytics from '../lib/analytics';
import { impressionHistory } from '../hooks/useImpressions';

/* globals gtag */

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

function MyApp(props) {
  const {
    Component,
    pageProps,
    router,
    emotionCache = clientSideEmotionCache,
  } = props;
  const {
    analyticsProps = {},
  } = pageProps;

  useEffect(() => {
    const firePageUpdate = () => {
      analytics.page({ ...analyticsProps });
      impressionHistory.reset();
    };
    // Log pageview on transition to a new page without a window reload.
    router.events.on('routeChangeComplete', firePageUpdate);
    return () => {
      router.events.off('routeChangeComplete', firePageUpdate);
    };
  }, [router, analyticsProps]);

  // This cluster handles page logging and optimization for Google Tag Manager and Analytics.
  useEffect(() => {
    // Load GTM and log initial page exactly once.
    const initializeGTM = () => {
      if (gtag) gtag('set', { server_container_url: process.env.NEXT_PUBLIC_GTM_SERVER_URL });
      if (!window.GTMSet) {
        window.GTMSet = true;
        analytics.plugins.enable('google-tag-manager');
        analytics.page({ ...analyticsProps });
      }
    };

    // Activate GTM on scroll, mouse movement, or mobile touch
    const GTMEventCallback = (event) => {
      window.removeEventListener(event.type, GTMEventCallback);
      initializeGTM();
    };
    ['scroll', 'mousemove', 'touchstart'].forEach((actionType) => { window.addEventListener(actionType, GTMEventCallback); });

    // Load GTM 6s after the DOM content has finished loading
    // if none of the above events triggered it
    setTimeout(() => {
      initializeGTM();
    }, 6000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const onRedirectCallback = (appState) => {
    // fires only on begin new login session
    analytics.track('login', {});
    router.push(appState && appState.returnTo ? appState.returnTo : '/');
  };

  const getLayout = Component.getLayout || ((page) => page);

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={LIGHT_THEME}>
        <PageLoadedProvider>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <GlobalFonts />
          <Auth0Provider
            domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN || 'login.makemymove.com'}
            clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || 'QeEWkViICuH3tfr6ym4Vj6Z1Ius9m38C'}
            authorizationParams={{
              redirect_uri: `${typeof window !== 'undefined' ? window.location.origin : 'http://localhost:3000'}/callback`,
            }}
            onRedirectCallback={onRedirectCallback}
          >
            <UserFavoritesProvider>
              <ProgramStatusProvider>
                {getLayout(<Component {...pageProps} />)}
              </ProgramStatusProvider>
            </UserFavoritesProvider>
          </Auth0Provider>
        </PageLoadedProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default wrapper.withRedux(MyApp);
